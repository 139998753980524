import { Fragment } from 'react';
import CancelationReasonsModal from 'src/components/modal/cancelation-reasons-modal';
import InfoModal from 'src/components/modal/info-modal';
import ReminderModal from 'src/components/modal/reminder-modal';
import ShareModal from 'src/components/modal/share-modal';
import SubscriptionConfirmedModal from 'src/components/modal/subscription-confirmed-modal';
import SubscriptionModal from 'src/components/modal/subscription-modal';

export default function ModalManager() {
  /**
   * @todo Currently the ModalManager initiates all modals at once,
   * ideally it should observe the modal state and depending on which modal is open,
   * dynamically load and display that modal content. This would help us reduce the bundle size.
   * The current modal structure should allow this kind of refactoring.
   */
  return (
    <Fragment>
      <InfoModal />
      <ReminderModal />
      <ShareModal />
      <SubscriptionModal />
      <SubscriptionConfirmedModal />
      <CancelationReasonsModal />
    </Fragment>
  );
}
